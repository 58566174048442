import { lazy, Suspense } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Spinner from "../spinner/spinner";
import Modal from "../modal/modal";
import MobMenu from "../mobMenu/mobMenu";
import Footer from "../content/footer/footer";
import Header from "../content/header/header";
import ImprovePage from "../pages/ImprovePage";

const MainPage = lazy(() => import("../pages/MainPage"));

const App = () => {
  return (
    <Router basename="/">
      <div className="App">
        <div className="wrapper">
          <h1 className="visually-hidden">FaceScan by Greenway</h1>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header />
                    <MainPage />
                    <Footer />
                    <Modal />
                  </>
                }
              />
              <Route path="/improve" element={
                <>
                  <ImprovePage />
                </>
              } />
              <Route path="/photo" />
            </Routes>
          </Suspense>
          <MobMenu />
        </div>
      </div>
    </Router>
  );
};

export default App;
