// CombinedProvider.js
import React from 'react';
import { AuthProvider } from './authProvider';
import { LanguageRegionProvider } from './LanguageRegionContext';


const CombinedProvider = ({ children }) => {
  return (
    <AuthProvider>
      <LanguageRegionProvider>
        {children}
      </LanguageRegionProvider>
    </AuthProvider>
  );
};

export default CombinedProvider;
