// authContext.js
import React, { createContext, useContext } from "react";
import { useToken } from "../../services/getToken";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { accessToken, setAccessToken, tempToken, fetchTempToken } = useToken();
  const isLoggedIn = Boolean(accessToken);

  const login = (token) => {
    setAccessToken(token);
  };

  const logout = () => {
    setAccessToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        tempToken,
        fetchTempToken,
        setAccessToken,
        isLoggedIn,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
