import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import allImages from '../../../utils/images';
import 'rc-slider/assets/index.css';
import RcSlider from 'rc-slider';

const ImproveSurvey = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 9;
  const [answers, setAnswers] = useState({
    test_1: '',
    test_2: [],
    test_3: '',
    test_4: '',
    test_5: '',
    test_6: '',
    test_7: '',
    test_8: '',
    test_9: 0,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();
  const questionRef = useRef(null);
  const [errors, setErrors] = useState({});
  const fireworksRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isSubmitted) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        navigate('/');
      }, 3000);
      if (fireworksRef.current) {
        const stars = fireworksRef.current.querySelectorAll('.improve__star');
        stars.forEach((star) => {
          gsap.fromTo(
            star,
            { scale: 0, opacity: 1 },
            {
              scale: Math.random() * 1.5 + 0.5,
              x: Math.random() * 200 - 100,
              y: Math.random() * 200 - 100,
              opacity: 0,
              duration: 1.5,
              ease: 'power1.out',
              delay: Math.random() * 0.5,
            }
          );
        });
      }
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isSubmitted, navigate]);

  const animateStepChange = (direction) => {
    const tl = gsap.timeline();
    tl.to(questionRef.current, {
      opacity: 0,
      x: direction === 'next' ? -50 : 50,
      duration: 0.5,
      ease: 'power1.out',
      onComplete: () => {
        setCurrentStep((prev) => (direction === 'next' ? prev + 1 : prev - 1));
      },
    }).to(questionRef.current, {
      opacity: 1,
      x: 0,
      duration: 0.5,
      ease: 'power1.in',
    });
  };

  const handleNext = () => {
    if (currentStep < totalSteps) {
      animateStepChange('next');
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      animateStepChange('prev');
    }
  };

  const handleInputChange = (step, value, type) => {
    setAnswers((prev) => {
      if (type === 'checkbox') {
        const updatedAnswers = prev[step].includes(value)
          ? prev[step].filter((item) => item !== value)
          : [...prev[step], value];
        return { ...prev, [step]: updatedAnswers };
      } else {
        return { ...prev, [step]: value };
      }
    });

    if (type === 'radio') {
      setTimeout(() => {
        handleNext();
      }, 100);
    }
  };

  const handleSkip = () => {
    const stepKey = `test_${currentStep}`;
    setAnswers((prev) => ({
      ...prev,
      [stepKey]: '',
    }));
    handleNext();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // сервак
    console.log("Ответы отправлены:", answers);
    setAnswers({
      test_1: '',
      test_2: [],
      test_3: '',
      test_4: '',
      test_5: '',
      test_6: '',
      test_7: '',
      test_8: '',
      test_9: 5,
    });
    setIsSubmitted(true);
  };

  const renderRadioOptions = (step, options) => {
    return options.map((option, index) => (
      <label key={index} className={answers[step] === option ? 'selected' : ''}>
        <input
          type="radio"
          name={step}
          checked={answers[step] === option}
          onChange={() => handleInputChange(step, option, 'radio')}
          onClick={() => {
            if (answers[step] === option) {
              setTimeout(() => {
                handleNext();
              }, 100);
            }
          }}
        />
        {option}
      </label>
    ));
  };

  const renderCheckboxOptions = (step, options) => {
    return options.map((option, index) => (
      <label key={index} className={answers[step].includes(option) ? 'selected' : ''}>
        <input
          type="checkbox"
          name={step}
          checked={answers[step].includes(option)}
          onChange={() => handleInputChange(step, option, 'checkbox')}
        />
        {option}
      </label>
    ));
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <h3>Насколько просто ориентироваться на страницах сервиса?</h3>
            <div className='improve__answers'>
              {renderRadioOptions('test_1', ['Просто', 'Удовлетворительно', 'Довольно сложно', 'Очень сложно'])}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h3>Насколько просто вам было пройти онлайн-диагностику?</h3>
            <div className='improve__answers'>
              {renderCheckboxOptions('test_2', [
                'Очень просто',
                'Долго грузились страницы',
                'Зависла онлайн диагностика по фотографии',
                'Некоторые вопросы были мне непонятны',
              ])}
            </div>
            <button type="button" onClick={handleNext} className="btn btn--transblue">Далее</button>
          </>
        );
      case 3:
        return (
          <>
            <h3>Считаете ли вы, что результаты онлайн-диагностики полные и информативные?</h3>
            <div className='improve__answers'>
              {renderRadioOptions('test_3', [
                'Результаты полные и содержат всю необходимую информацию',
                'Результаты понятны, но содержат не всю информацию',
                'Результаты непонятны или поверхностны',
                'Мне не показали результат',
              ])}
            </div>
          </>
        );
      case 4:
        return (
          <>
            <h3>Была ли вам полезна индивидуальная подборка средств для кожи?</h3>
            <div className='improve__answers'>
              {renderRadioOptions('test_4', ['Полезна', 'Некоторые позиции были полезны', 'Бесполезна'])}
            </div>
          </>
        );
      case 5:
        return (
          <>
            <h3>Считаете ли вы, что сервис GreenWay полезен?</h3>
            <div className='improve__answers'>
              {renderRadioOptions('test_5', ['Да, сервис полезен', 'Частично полезен', 'Нет, бесполезен'])}
            </div>
          </>
        );
      case 6:
      case 7:
      case 8:
        return (
          <>
            <h3>
              {currentStep === 6 && 'Опишите, что вам понравилось в использовании сервиса?'}
              {currentStep === 7 && 'Опишите, что вам не понравилось в использовании сервиса?'}
              {currentStep === 8 && 'Что на ваш взгляд стоит улучшить в работе сервиса?'}
            </h3>
            <textarea
              name={`test_${currentStep}`}
              rows="5"
              value={answers[`test_${currentStep}`]}
              onChange={(e) => {
                handleInputChange(`test_${currentStep}`, e.target.value, 'text');
                if (e.target.value.trim() !== '') {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`test_${currentStep}`]: '',
                  }));
                }
              }}
            />
            {errors[`test_${currentStep}`] && (
              <span className="improve__error">{errors[`test_${currentStep}`]}</span>
            )}
            <div className='improve__btns'>
              <button
                type="button"
                onClick={() => {
                  if (answers[`test_${currentStep}`].trim() === '') {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      [`test_${currentStep}`]: 'Это поле обязательно для заполнения.',
                    }));
                  } else {
                    handleNext();
                  }
                }}
                className="btn btn--blue"
              >
                Далее
              </button>
              <button type="button" onClick={handleSkip} className="btn btn--transblue">Пропустить</button>
            </div>
          </>
        );
      case 9:
        return (
          <>
            <h3>Оцените полезность сервиса от 1 до 10</h3>
            <div className="improve__slider">
              <ul className="improve__numbers">
                {[...Array(10)].map((_, index) => (
                  <li
                    key={index}
                    className={`${answers.test_9 === index + 1 ? 'active' : ''
                      }`}
                  >
                    {index + 1}
                  </li>
                ))}
              </ul>

              <RcSlider
                min={1}
                max={10}
                value={answers.test_9}
                onChange={(value) => {
                  setAnswers((prev) => ({
                    ...prev,
                    test_9: value,
                  }));
                }}
                trackStyle={{ backgroundColor: '#007bff', height: 8 }}
                railStyle={{ backgroundColor: '#e0e0e0', height: 8 }}
                handleStyle={{
                  borderColor: '#007bff',
                  height: 24,
                  width: 24,
                  marginLeft: -12,
                  marginTop: -8,
                  backgroundColor: '#fff',
                }}
              />
            </div>
            <div className='improve__btns'>
              <button type="submit" className="btn btn--blue">Отправить</button>
            </div>
          </>
        );
      default:
        return <h3>Шаг не найден</h3>;
    }
  };
  return (
    <section className="improve">
      <div className='container'>
        <div className='improve__container'>
          <h2>Помогите нам стать лучше</h2>
          {isSubmitted ? (
            <div className="improve__success">
              <h3 className="improve__title">
                Спасибо!
              </h3>
              <span>Возвращаемся на главную</span>
              <div className="ask__submitted">
                <allImages.InstBird />
              </div>
              <div ref={fireworksRef} className="improve__fireworks">
                {[...Array(20)].map((_, index) => (
                  <div key={index} className="improve__star"></div>
                ))}
              </div>
              <p className="ask__countdown">
                Переход произойдет через: <strong>{countdown} сек.</strong>
              </p>
            </div>
          ) : (
            <form className="improve__form" onSubmit={handleSubmit}>
              <div className="improve__pager">
                <div className="improve__position">
                  {currentStep > 1 && (
                    <button type="button" onClick={handlePrev}>
                      <allImages.NextArrow />
                    </button>
                  )}
                  <span>Вопрос <b>{currentStep}</b> из <b>{totalSteps}</b></span>
                </div>
                <ul className="improve__dots">
                  {[...Array(totalSteps)].map((_, index) => (
                    <li
                      key={index}
                      className={`${currentStep === index + 1 ? 'active' : ''} ${index + 1 < currentStep ? 'done' : ''}`}
                    >
                      {index + 1 < currentStep && <allImages.InstBird />}
                    </li>
                  ))}
                </ul>
              </div>

              <div
                ref={questionRef}
                className="improve__question"
              >
                {renderStepContent()}
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImproveSurvey;
