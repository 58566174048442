import { memo, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import allImages from "../../utils/images";
import Modal, { closeModal } from "./modal";

const SelectionModal = memo(({ isOpen, onClose, title, type, selectedOption, setSelectedOption }) => {
  const options = [
    { id: uuidv4(), nameLang: "Русский", nameReg: "Россия", flagSrc: allImages.LangRu, code: 'ru' },
    { id: uuidv4(), nameLang: "English", nameReg: "Europe", flagSrc: allImages.LangEng, code: 'en' },
    { id: uuidv4(), nameLang: "Qazaq", nameReg: "Qazaqstan", flagSrc: allImages.LangKz, code: 'kz' },
    { id: uuidv4(), nameLang: "O'zbek", nameReg: "O‘zbekiston", flagSrc: allImages.LangUz, code: 'uz' },
    { id: uuidv4(), nameLang: "Tiếng Việt", nameReg: "Việt Nam", flagSrc: allImages.LangVn, code: 'vn' },
    { id: uuidv4(), nameLang: "Français", nameReg: "France", flagSrc: allImages.LangFr, code: 'fr' },
    { id: uuidv4(), nameLang: "Čeština", nameReg: "Чехия", flagSrc: allImages.LangCz, code: 'cs' },
    { id: uuidv4(), nameLang: "Italiano", nameReg: "Italia", flagSrc: allImages.LangIt, code: 'it' },
    { id: uuidv4(), nameLang: "ქართული", nameReg: "საქართველო", flagSrc: allImages.LangGe, code: 'ge' },
    { id: uuidv4(), nameLang: "Español", nameReg: "España", flagSrc: allImages.LangEs, code: 'es' },
    { id: uuidv4(), nameLang: "Español", nameReg: "México", flagSrc: allImages.LangMx, code: 'mx' },
    { id: uuidv4(), nameLang: "Slovenčina", nameReg: "Slovenska", flagSrc: allImages.LangSk, code: 'sk' },
  ];

  useEffect(() => {
    const savedOption = localStorage.getItem(type);
    if (savedOption) {
      setSelectedOption(JSON.parse(savedOption));
    }
  }, [type, setSelectedOption]);

  const sendSelectionToServer = async (selectedCode) => {
    try {
      const serverUrl = type === "language" ? "/api/set-language" : "/api/set-region";
      console.log(`Sending to ${serverUrl}:`, { selectedCode });

    } catch (error) {
      console.error("Ошибка при отправке на сервер:", error);
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    sendSelectionToServer(option.code);
    localStorage.setItem(type, JSON.stringify(option));
    closeModal(onClose);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => closeModal(onClose)}
      title={title}
    >
      <ul className="selection__list">
        {options.map((option) => (
          <li key={option.id}>
            <input
              type="radio"
              id={`${type}-${option.id}`}
              name={type}
              value={option.code}
              checked={selectedOption.code === option.code}
              onChange={() => handleOptionChange(option)}
            />
            <label
              htmlFor={`${type}-${option.id}`}
              className={`${selectedOption.code === option.code ? 'selected' : ''}`}
              onClick={() => handleOptionChange(option)}
            >
              <img src={option.flagSrc} alt={`${type === 'language' ? option.nameLang : option.nameReg} флаг`} />
              <span>{type === 'language' ? option.nameLang : option.nameReg}</span>
              {selectedOption.code === option.code ? (
                <div className="selection__selected">
                  <allImages.InstBird />
                </div>
              ) : (
                <div className="selection__point">
                  <allImages.NextArrow />
                </div>
              )}
            </label>
          </li>
        ))}
      </ul>
    </Modal>
  );
});

export default SelectionModal;
