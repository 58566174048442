import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import allImages from "../../utils/images";
import gsap from "gsap";

const Modal = ({ isOpen, onClose = () => { }, title, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";

      const overlayElement = document.querySelector(".modal-overlay");
      if (overlayElement) {
        gsap.fromTo(
          overlayElement,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.3,
            ease: "power1.out",
          }
        );
      }

      const modalElement = document.querySelector(".modal");
      if (modalElement) {
        gsap.fromTo(
          modalElement,
          { y: 50, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: "power2.out",
          }
        );
      }
    } else {
      const modalElement = document.querySelector(".modal");
      if (modalElement) {
        gsap.to(modalElement, {
          opacity: 0,
          y: 50,
          duration: 0.3,
          ease: "power1.in",
          onComplete: onClose,
        });
      }

      const overlayElement = document.querySelector(".modal-overlay");
      if (overlayElement) {
        gsap.to(overlayElement, {
          opacity: 0,
          duration: 0.3,
          ease: "power1.in",
        });
      }
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleEscClose = (event) => {
    if (event.key === "Escape" && typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscClose);
    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [onClose]);

  const handleOverlayClick = (event) => {
    if (
      event.target.classList.contains("modal-overlay") &&
      typeof onClose === "function"
    ) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal">
        {title && <h2 className="modal__title">{title}</h2>}
        <button className="modal__close" onClick={onClose}>
          <allImages.ModalClose />
        </button>
        <div className="modal__content">{children}</div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export const openModal = (setterFunction, modalName) => {
  setterFunction(modalName);
};

export const closeModal = (setterFunction) => {
  setterFunction(null);
};

export default Modal