import { NavLink } from "react-router-dom";
import allImages from "../../utils/images";
import { useAuthContext } from "../providers/authProvider";

const SideDashboard = ({ type }) => {
  const { isLoggedIn } = useAuthContext();

  const navData = {
    test: [
      { to: "/photo", className: "dashboard__photo", icon: <allImages.DashPhoto /> },
      { to: "/test", className: "dashboard__test", icon: <allImages.DashTest /> },
      { to: "/result", className: "dashboard__res", icon: <allImages.DashRes /> },
      { to: "/recs", className: "dashboard__recs", icon: <allImages.DashRecs /> },
    ],
    profile: [
      { to: "/profile-tests", className: "dashboard__profile-photo", icon: <allImages.DashPhoto />, text: "Тестирования" },
      { to: "/profile-recs", className: "dashboard__profile-recs", icon: <allImages.DashPhoto />, text: "Средства" },
      { to: "/profile-data", className: "dashboard__profile-data", icon: <allImages.DashPhoto />, text: "Данные" },
    ],
    admin: [
      { to: "/profile-tests", className: "dashboard__profile-photo", icon: <allImages.DashPhoto />, text: "Тестирования" },
      { to: "/profile-recs", className: "dashboard__profile-recs", icon: <allImages.DashPhoto />, text: "Средства" },
      { to: "/profile-data", className: "dashboard__profile-data", icon: <allImages.DashPhoto />, text: "Данные" },
      { to: "/profile-admin", className: "dashboard__profile-admin", icon: <allImages.DashPhoto />, text: "Админ" },
    ],
    default: [
      { to: "/photo", className: "dashboard__begin", icon: <allImages.DashPhoto /> },
    ],
  };

  const currentNavItems = navData[type] || navData.default;

  return (
    <>
      <NavLink to="/">
        <picture>
          <source
            srcSet={`${allImages.DashLogoWebp2x}`}
            type="image/webp"
          />
          <img
            src={allImages.DashLogoWebp}
            width="54"
            height="40"
            alt="логотип FaceScan."
          />
        </picture>
      </NavLink>
      <nav className="dashboard__nav">
        <ul>
          {currentNavItems.map((item, index) => (
            <li key={index}>
              <NavLink
              aria-label={item.className}
                to={item.to}
                className={({ isActive }) =>
                  isActive ? `${item.className} active` : item.className
                }
              >
                {item.icon}
                {item.text && <span>{item.text}</span>}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <ul className="dashboard__bottom">
        <li>
          <NavLink
          aria-label={isLoggedIn ? 'Просмотреть профиль' : 'Войти'}
            className={({ isActive }) =>
              isActive ? "dashboard__profile active" : "dashboard__profile"
            }
            to={isLoggedIn ? '/profile' : '/login'}
          >
            {isLoggedIn ? <allImages.DashProfile /> : <allImages.DashLogin />}
          </NavLink>
        </li>
        <li>
          <NavLink
          aria-label="На главную"
            className="dashboard__home active"
            to="/"
          >
            <allImages.DashHome />
          </NavLink>
        </li>
      </ul>
    </>
  );
}

export default SideDashboard;
