import { useState, useEffect, useCallback } from "react";
import { useHttp } from "../hooks/httpHook";

export const useToken = () => {
  const { request } = useHttp()
  const _apiBase = 'https://ozon.api.beautyscan.ru';

  const [accessToken, setAccessToken] = useState(null);
  const [tempToken, setTempToken] = useState(null);

  // const fetchAccessToken = useCallback(async () => {
  //   try {
  //     const res = await request(`${_apiBase}/users/access_token_create/`, "GET");
  //     const token = res?.access_token;

  //     if (token) {
  //       setAccessToken(token);
  //       return token;
  //     } else {
  //       throw new Error("Access token not received");
  //     }
  //   } catch (e) {
  //     console.error("Failed to get access token", e);
  //     throw e;
  //   }
  // }, [request]);

  const fetchTempToken = useCallback(async () => {
    try {
      if (!tempToken) {
        const res = await request(`${_apiBase}/users/temp_user_create/`, "GET");
        const token = res?.temp_token;

        if (token) {
          setTempToken(token);
          return token;
        } else {
          throw new Error("Temp token not received");
        }
      } else {
        return tempToken;
      }
    } catch (e) {
      console.error("Failed to get temp token", e);
      throw e;
    }
  }, [request, tempToken]);

  return { accessToken, setAccessToken, tempToken, fetchTempToken };
};
