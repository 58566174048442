import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./header";
import allImages from "../../../utils/images";
import { openModal, closeModal } from "../../modal/modal";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import QuestionModal from "../../modal/QuestionModal";
import SelectionModal from "../../modal/locationModal";
import { useLanguageRegion } from "../../providers/LanguageRegionContext";

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const { lang, region, updateLang, updateRegion } = useLanguageRegion();
  const [openModalName, setOpenModalName] = useState(null);
  const headerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      headerRef.current,
      { y: -100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        scrollTrigger: {
          trigger: headerRef.current,
          start: "top top",
          toggleActions: "play reverse play reverse",
        },
      }
    );
  }, []);

  return (
    <>
      <header className="header" ref={headerRef}>
        <div className="container">
          <div className="header__container">
            <div className="header__top">
              <div className="header__nav">
                <button type="button" onClick={() => openModal(setOpenModalName, "language")}>
                  <allImages.LangIcon />
                  <span>{lang.nameLang}</span>
                </button>
                <button type="button" onClick={() => openModal(setOpenModalName, "region")}>
                  <allImages.RegIcon />
                  <span>{region.nameReg}</span>
                </button>
              </div>
              <div className="header__ask">
                <NavLink
                  className="header__ask-link header__ask-link--faq"
                  to="/faq"
                >
                  Вопросы / Ответы
                </NavLink>
                <NavLink
                  className="header__ask-link header__ask-link--improve"
                  to="/improve"
                >
                  Помогите нам стать лучше
                </NavLink>
              </div>
            </div>
            <div className="header__bottom">
              <NavLink to="/">
                <picture>
                  <source
                    srcSet={`${allImages.logoWebp} 1x, ${allImages.logoRetinaWebp} 2x`}
                    type="image/webp"
                  />
                  <img
                    src={allImages.logo}
                    srcSet={allImages.logoRetina}
                    width="94"
                    height="72"
                    alt="логотип FaceScan."
                  />
                </picture>
              </NavLink>
              <div className="header__personal">
                <button
                  className="header__personal-ask btn btn--white"
                  type="button"
                  onClick={() => openModal(setOpenModalName, "question")}
                >
                  <allImages.QuestionIcon />
                  Задать вопрос
                </button>
                <NavLink
                  className="header__personal-login btn btn--trans"
                  to="login"
                >
                  <allImages.UserIcon />
                  Войти
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </header>

      {openModalName === "language" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Выберите язык"
          type="language"
          selectedOption={lang}
          setSelectedOption={updateLang}
        />
      )}

      {openModalName === "region" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Выберите регион"
          type="region"
          selectedOption={region}
          setSelectedOption={updateRegion}
        />
      )}

      {openModalName === "question" && (
        <QuestionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
        />
      )}
    </>
  );
};

export default Header;
