import React, { createContext, useContext, useEffect, useState } from 'react';

const LanguageRegionContext = createContext();

export const LanguageRegionProvider = ({ children }) => {
  const [lang, setLang] = useState({ nameLang: 'Русский', code: 'ru' });
  const [region, setRegion] = useState({ nameReg: 'Россия', code: 'ru' });

  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    if (savedLang) {
      setLang(JSON.parse(savedLang));
    }

    const savedRegion = localStorage.getItem('region');
    if (savedRegion) {
      setRegion(JSON.parse(savedRegion));
    }
  }, []);

  const updateLang = (newLang) => {
    if (newLang.code !== lang.code) {
      setLang(newLang);
      localStorage.setItem('language', JSON.stringify(newLang));
    }
  };

  const updateRegion = (newRegion) => {
    if (newRegion.code !== region.code) {
      setRegion(newRegion);
      localStorage.setItem('region', JSON.stringify(newRegion));
    }
  };

  const clearStorage = () => {
    localStorage.removeItem('language');
    localStorage.removeItem('region');
    setLang({ nameLang: 'Русский', code: 'ru' });
    setRegion({ nameReg: 'Россия', code: 'ru' });
  };

  return (
    <LanguageRegionContext.Provider value={{ lang, region, updateLang, updateRegion, clearStorage }}>
      {children}
    </LanguageRegionContext.Provider>
  );
};

export const useLanguageRegion = () => useContext(LanguageRegionContext);
