import { Helmet } from "react-helmet"
import ImproveSurvey from "../content/improve/improve"
import SideDashboard from "../dashboard/dashboard"

const ImprovePage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Помогите нам стать лучше" />
        <title>Improve our service</title>
      </Helmet>
      <main>
      <aside className="dashboard">
        <SideDashboard />
      </aside>
        <ImproveSurvey />
      </main>
    </>
  )
}

export default ImprovePage