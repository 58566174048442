import React, { useState, useEffect } from "react";
import Modal from "../modal/modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import allImages from "../../utils/images";


const QuestionModal = ({ isOpen, onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    let timer;
    if (isSubmitted) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      setTimeout(() => {
        onClose();
      }, 3000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isSubmitted, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={isSubmitted ? "Отправлено!" : "Задать вопрос"}>
      {isSubmitted ? (
        <div className="ask__success">
          <p className="modal__description">
            В ближайшее время с вами свяжутся сотрудники нашего сервиса. Спасибо!
          </p>
          <div className="ask__submitted">
            <allImages.InstBird />
          </div>
          <p className="ask__countdown">
            Окно закроется автоматически через: <strong>{countdown} сек.</strong>
          </p>
        </div>
      ) : (
        <>
          <p className="modal__description">
            Свяжитесь с нами, если у вас есть вопрос или пожелания по работе сервиса
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              text: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(2, "Минимум 2 знака")
                .required("Поле обязательно к заполнению"),
              email: Yup.string()
                .email("Неправильный формат")
                .required("Поле обязательно к заполнению"),
              text: Yup.string()
                .min(10, "не менее 10 символов")
                .required("Поле обязательно к заполнению"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setIsSubmitted(true);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="ask__form">
                <label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Имя"
                    className="ask__input"
                  />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="ask__error"
                  />
                </label>
                <label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail для связи"
                    className="ask__input"
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="ask__error"
                  />
                </label>
                <label>
                  <Field
                    as="textarea"
                    id="text"
                    name="text"
                    placeholder="Текст сообщения"
                    className="ask__textarea"
                    rows="10"
                  />
                  <ErrorMessage
                    name="text"
                    component="span"
                    className="ask__error"
                  />
                </label>
                <button
                  type="submit"
                  className="ask__submit btn btn--blue"
                  disabled={isSubmitting}
                >
                  Отправить
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};

export default QuestionModal;
